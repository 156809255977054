import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import qs from 'qs';

class ApiController {
  private client: AxiosInstance;

  constructor() {
    const root = document.getElementById('root');
    const apiUrl =
      root?.getAttribute('data-api-url') || process.env.REACT_APP_BASE_URI;
    this.client = axios.create({
      baseURL: apiUrl,
    });
  }

  public get<T, D = any>({
    url,
    data,
    config,
  }: {
    url: string;
    data?: D;
    config?: AxiosRequestConfig;
  }) {
    return this.processRequest<T>({
      method: 'get',
      url: `${url}?${qs.stringify(data)}`,
      data,
      config,
    });
  }

  public post<T, D = any>({
    url,
    data,
    config,
  }: {
    url: string;
    data?: D;
    config?: AxiosRequestConfig;
  }) {
    return this.processRequest<T, D>({ method: 'post', url, data, config });
  }

  public put<T, D = any>({
    url,
    data,
    config,
  }: {
    url: string;
    data?: D;
    config?: AxiosRequestConfig;
  }) {
    return this.processRequest<T, D>({ method: 'put', url, data, config });
  }

  public delete<T, D = any>({
    url,
    data,
    config,
  }: {
    url: string;
    data?: D;
    config?: AxiosRequestConfig;
  }) {
    return this.processRequest<T, D>({ method: 'delete', url, data, config });
  }

  private async processRequest<T, D = unknown>({
    method,
    url,
    data,
    config = {},
  }: {
    method: string;
    url: string;
    data?: D;
    config?: AxiosRequestConfig;
  }) {
    let headers: AxiosRequestHeaders = {};
    if (typeof window !== 'undefined' && window.token) {
      headers = {
        ...(config?.headers || {}),
        Authorization: `Bearer ${window.token}`,
      };
    }

    const response = await this.client.request<T, AxiosResponse<T>, D>({
      method,
      url,
      data,
      ...config,
      headers,
    });

    return response;
  }
}

export default new ApiController();
