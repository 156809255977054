import { FC } from "react";
import { CurrentPagination } from "src/Models/CurrentFilter";
import paginate from "src/Utils/Paginate";

import styles from "./App.module.css";

interface PaginationProps {
  currentPage: CurrentPagination;
  onPageChange: (p: string | undefined) => void;
  paginationData: {
    from: number;
    to: number;
    last_page: number;
  };
  top: boolean;
  id: string;
  isCourses: boolean;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  paginationData,
  top,
  id,
  isCourses,
  onPageChange,
}) => {
  const view = isCourses ? "courses" : "list";
  const _id = `pagination-top-${view}`;
  return (
    <div id={id} className={styles.pagination}>
      <button
        onClick={() => {
          if (!top) {
            const scrollDiv = document.getElementById(_id)?.offsetTop;
            window.scrollTo({
              top: (scrollDiv || 0) - 30,
              behavior: "smooth",
            });
          }

          onPageChange("1");
        }}
        className={`first_page ${currentPage.page === "1" ? "disable" : ""}`}
      >
        {"<<"}
      </button>
      <button
        onClick={() => {
          if (!top) {
            const scrollDiv = document.getElementById(_id)?.offsetTop;
            window.scrollTo({
              top: (scrollDiv || 0) - 30,
              behavior: "smooth",
            });
          }

          const page =
            String(paginationData?.from) === String(currentPage.page)
              ? currentPage.page
              : String(Number(currentPage.page) - 1);
          onPageChange(page);
        }}
        className={`previous_page ${
          currentPage.page === String(paginationData?.from) ? "disable" : ""
        }`}
      >
        {"<"}
      </button>
      {paginate(Number(currentPage.page), paginationData?.last_page).map(
        (page: { i: number; index: number }) =>
          !isNaN(Number(page.i)) ? (
            <button
              value={page.i}
              key={page.index}
              onClick={() => {
                if (!top) {
                  const scrollDiv = document.getElementById(_id)?.offsetTop;
                  window.scrollTo({
                    top: (scrollDiv || 0) - 30,
                    behavior: "smooth",
                  });
                }

                onPageChange(String(page.i));
              }}
              className={currentPage.page === String(page.i) ? "active" : ""}
            >
              {page.i}
            </button>
          ) : (
            <span key={page.index}>{page.i}</span>
          )
      )}
      <button
        onClick={() => {
          if (!top) {
            const scrollDiv = document.getElementById(_id)?.offsetTop;
            window.scrollTo({
              top: (scrollDiv || 0) - 30,
              behavior: "smooth",
            });
          }

          const page =
            String(paginationData?.last_page) === String(currentPage.page)
              ? currentPage.page
              : String(Number(currentPage.page) + 1);

          onPageChange(page);
        }}
        className={`next_page ${
          currentPage.page === String(paginationData?.last_page)
            ? "disable"
            : ""
        }`}
      >
        {">"}
      </button>

      <button
        onClick={() => {
          if (!top) {
            const scrollDiv = document.getElementById(_id)?.offsetTop;
            window.scrollTo({
              top: (scrollDiv || 0) - 30,
              behavior: "smooth",
            });
          }

          const page = String(paginationData?.last_page);

          onPageChange(page);
        }}
        className={`last_page ${
          currentPage.page === String(paginationData?.last_page)
            ? "disable"
            : ""
        }`}
      >
        {">>"}
      </button>
    </div>
  );
};

export default Pagination;
