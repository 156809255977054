const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((v, i) => i + start);
};

const paginate = (currentPage: number, lastPage: number) => {
  let delta: number;
  if (lastPage <= 7) {
    // delta === 7: [1 2 3 4 5 6 7]
    delta = 7;
  } else {
    // delta === 2: [1 ... 4 5 6 ... 10]
    // delta === 4: [1 2 3 4 5 ... 10]
    delta = currentPage > 3 && currentPage < lastPage - 3 ? 2 : 3;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === lastPage) {
    range.start += 1;
    range.end += 1;
  }

  let pages: any =
    currentPage > delta
      ? getRange(
          Math.min(range.start, lastPage - delta),
          Math.min(range.end, lastPage)
        )
      : getRange(1, Math.min(lastPage, delta + 1));

  const withDots = (value: number, pair: (string | number)[]) =>
    pages.length + 1 !== lastPage ? pair : [value];

  if (pages[0] !== 1) {
    pages = withDots(1, [1, "..."]).concat(pages);
  }

  if (pages[pages.length - 1] < lastPage) {
    pages = pages.concat(withDots(lastPage, ["...", lastPage]));
  }

  return pages.map((i: any, index: any) => {
    return { i, index };
  });
};

export default paginate;
