export interface BaseError<E> {
  success: false;
  error: E;
}

export interface BaseSuccess<S> {
  success: true;
  payload: S;
}

export type BaseResponse<S, E = string> = BaseError<E> | BaseSuccess<S>;

export const generateErrorResponse = <S>(
  error: unknown
): BaseResponse<S, string> => {
  return {
    success: false,
    error:
      (error as Error)?.message ||
      "We couldn't process the information provided",
  };
};
