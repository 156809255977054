import axios from "axios";
import { Classes } from "src/Models/Classes";
import { Course } from "src/Models/Course";

import ApiController from "./ApiController";
import { BaseResponse, generateErrorResponse } from "./Response/BaseResponse";
import { Class } from "src/Models/Class";
import { ClassesFilter } from "src/Models/ClassesFilter";
import { Courses } from "src/Models/Courses";
import { CurrentFilter, CurrentPagination } from "src/Models/CurrentFilter";

class ClassesController {
  async getClasses(
    currentFilter?: CurrentFilter,
    currentPage?: CurrentPagination
  ): Promise<BaseResponse<Classes>> {
    let url = "api/classes";

    const root = document.getElementById("root");
    const rootFilters: CurrentFilter = JSON.parse(
      root?.getAttribute("data-filters") || "{}"
    );

    const finalFilter: CurrentFilter = { ...currentFilter, ...rootFilters };

    const params = {
      length: finalFilter?.length || [],
      genre_id: finalFilter?.genre_id,
      modality: finalFilter?.modality,
      modality_url: finalFilter?.modality_url,
      sort_dir: "asc",
      sort_by: finalFilter?.sort_by,
      page: currentPage?.page || "1",
      days: finalFilter?.days,
      time_of_day: finalFilter?.time_of_day,
      price: finalFilter?.price,
      genre_feature_id: finalFilter?.genre_feature_id,
      genre_category_id: finalFilter?.genre_category_id,
      user_id: finalFilter?.user_id,
    };

    try {
      const result = await ApiController.get<Classes>({
        url,
        data: { ...params },
      });

      return {
        success: true,
        payload: result?.data || [],
      };
    } catch (error) {
      return generateErrorResponse(
        axios.isAxiosError(error) ? error : undefined
      );
    }
  }

  async getCourse(id: string): Promise<BaseResponse<Course>> {
    try {
      const result = await ApiController.get<Course>({
        url: `api/courses/${id}`,
      });
      return {
        success: true,
        payload: result?.data || {},
      };
    } catch (error) {
      return generateErrorResponse(
        axios.isAxiosError(error) ? error : undefined
      );
    }
  }

  async mapCourseInfo(data: Class[]): Promise<Class[]> {
    const classes = data.map(async (item): Promise<Class> => {
      return {
        id: item.id,
        slug: item.slug,
        name: item.name,
        thumbnail: item.thumbnail,
        start_date: item.start_date,
        start_time: item.start_time,
        end_time: item.end_time,
        favorite: item.favorite,
        modality: item.modality,
        modality_url: item.modality_url,
        teacher: item.teacher,
        level: item.level,
        length: item.length,
        length_format: item.length_format,
        product_id: item.product_id,
        can_enroll: item.can_enroll,
        messages: item.messages,
        available_spots_text: item.available_spots_text,
        days: item.days,
        course: {
          id: item.course?.id as string,
          name: item.course?.name as string,
          description: item.course?.description as string,
          slug: item.course?.slug as string,
          description_short: item?.course?.description_short,
          genre: item?.course?.genre,
          thumbnail: item?.course.thumbnail,
          favorite: item?.course.favorite,
        },
      };
    });

    const mapped = await Promise.all(classes);
    return mapped;
  }

  async getFilters(): Promise<BaseResponse<ClassesFilter>> {
    try {
      const result = await ApiController.get<ClassesFilter>({
        url: "api/classes/filters",
      });
      return {
        success: true,
        payload: result?.data || null,
      };
    } catch (error) {
      return generateErrorResponse(
        axios.isAxiosError(error) ? error : undefined
      );
    }
  }

  async getCourses(
    currentFilter?: CurrentFilter,
    currentPage?: CurrentPagination
  ): Promise<BaseResponse<Courses>> {
    const root = document.getElementById("root");
    const rootFilters: CurrentFilter = JSON.parse(
      root?.getAttribute("data-filters") || "{}"
    );

    const finalFilter: CurrentFilter = { ...currentFilter, ...rootFilters };

    const params = {
      genre_category_id: currentFilter?.genre_category_id,
      genre_feature_id: currentFilter?.genre_feature_id,
      name: currentFilter?.name,
      most_popular: currentFilter?.sort_by === "most_popular",
      page: currentPage?.page || "1",
      user_id: finalFilter?.user_id,
    };

    try {
      const result = await ApiController.get<Courses>({
        url: `api/courses`,
        data: { ...params },
      });
      return {
        success: true,
        payload: result?.data || [],
      };
    } catch (error) {
      return generateErrorResponse(
        axios.isAxiosError(error) ? error : undefined
      );
    }
  }

  async getCoursesFilters(): Promise<BaseResponse<ClassesFilter>> {
    try {
      const result = await ApiController.get<ClassesFilter>({
        url: "api/courses/filters",
      });
      return {
        success: true,
        payload: result?.data || null,
      };
    } catch (error) {
      return generateErrorResponse(
        axios.isAxiosError(error) ? error : undefined
      );
    }
  }
}

export default new ClassesController();
