import { FC } from "react";

interface FilterViewProps {
  icon: string;
  icon_selected: string;
  title: string;
  onClick: (type: string, e: string) => void;
  id: string;
  active: boolean;
  type: string;
}

const FilterMenuView: FC<FilterViewProps> = ({
  icon,
  title,
  onClick,
  id,
  active,
  type,
  icon_selected,
}) => {
  return (
    <div
      className={active ? "filter_item active" : "filter_item"}
      onClick={() => { 
        if(window.screen.width < 768){
            const _id = `pagination-top-courses`;
            const scrollDiv = document.getElementById(_id)?.offsetTop;

            window.scrollTo({
              top: (scrollDiv || 0) - 120,
              behavior: "smooth",
            }); 
          }
          
          onClick(type, id)
        }
      }
    >
      {!active && icon_selected && (
        <img src={icon} alt={title} className="icon" />
      )}
      {active && icon_selected && (
        <div
          className="icon active"
          style={{
            WebkitMaskImage: `url(${icon_selected})`,
            maskImage: `url(${icon_selected})`,
          }}
        ></div>
      )}

      <p>{title}</p>
    </div>
  );
};

export default FilterMenuView;
