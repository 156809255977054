import { useEffect, useState, useMemo } from "react";
import ClassesController from "src/Api/ClassesController";
import Pagination from "src/Components/Pagination";
import { ClassesFilter } from "src/Models/ClassesFilter";
import { Course } from "src/Models/Course";
import { CurrentFilter, CurrentPagination } from "src/Models/CurrentFilter";
import { EntryPointData } from "../EntryPointData";
import FilterMenuView from "../ListClasses/Components/FilterMenuView";
import Select from 'react-select';
import { useHistory, useLocation } from "react-router-dom";

export const data: EntryPointData = {
  rootId: "root",
};

function App() {
  const [filterFromAPI, setInfo] = useState<ClassesFilter>();
  const [coursesData, setCourses] = useState<Course[]>([]);
  const [searchText, setSearchText] = useState("");
  const [paginationData, setPaginationData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({
    sort_by: "most_popular",
    genre_feature_id: searchParams.get("genre_feature_id")?.split(",") || [],
    genre_category_id: searchParams.get("genre_category_id")?.split(",") || [],
    genre_id: searchParams.get("genre_id")?.split(",") || [],
    name: "",
  });

  const sortOptions = [
    { value: 'most_popular', label: 'Most Popular' },
    { value: 'name', label: 'Alphabetical' },
  ];

  const setDeepLinking = () => {
    Object.keys({ ...currentFilter }).forEach((key: string) => {
      let value: string =
        typeof currentFilter[key as keyof CurrentFilter] === "string"
          ? (currentFilter[key as keyof CurrentFilter] as string)
          : (currentFilter[key as keyof CurrentFilter] as string[])?.join(",");
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
      history.replace({ search: searchParams.toString() });
    });
  };

  const setDeepLinkingPagination = (page: string) => {
    if (page) {
      searchParams.set("page", page);
    } else {
      searchParams.delete("page");
    }
    history.replace({ search: searchParams.toString() });
  };

  const [currentPage, setCurrentPagination] = useState<CurrentPagination>({
    page: searchParams.get("page") || "1",
  });

  useEffect(() => {
    const query = async () => {
      setLoading(true);
      setCurrentPagination({ page: currentPage.page });
      const result = await ClassesController.getCoursesFilters();
      setInfo(result.success ? result.payload : undefined);

      const courses = await ClassesController.getCourses(currentFilter);
      const coursesData = courses.success ? courses.payload?.data : [];
      const paginationData = courses.success ? courses.payload : [];
      setPaginationData(paginationData);
      setCourses(coursesData);
      setDeepLinking();
      setDeepLinkingPagination(currentPage.page || "1");
      setLoading(false);
    };
    query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  const onPageChange = async (page?: string) => {
    setLoading(true);
    const result = await ClassesController.getCoursesFilters();
    setInfo(result.success ? result.payload : undefined);
    setCurrentPagination({
      ...currentPage,
      page,
    });

    const courses = await ClassesController.getCourses(currentFilter, { page });
    const coursesData = courses.success ? courses.payload?.data : [];
    const paginationData = courses.success ? courses.payload : [];
    setPaginationData(paginationData);
    setCourses(coursesData);
    setDeepLinkingPagination(page || "1");
    setLoading(false);
  };

  return (
    <>
      <header className="class_header">
        <div className="filter_view">
          <div className="wrapper">
            {filterFromAPI?.genre_category_id?.values.map((item) => (
              <div key={item.id}>
                <FilterMenuView
                  icon_selected={item.icon_selected}
                  icon={item.icon}
                  title={item.name}
                  onClick={() => {
                    let CEvent = new CustomEvent('categoryFiltered', {
                      bubbles: true,
                      detail: { name: "" }
                    });
                    if (
                      currentFilter.genre_category_id?.includes(String(item.id))
                    ) {
                      setCurrentFilter({
                        ...currentFilter,
                        genre_category_id: [],
                        genre_feature_id: [],
                        name: "",
                      });
                    } else {
                      setCurrentFilter({
                        ...currentFilter,
                        genre_category_id: [String(item.id)],
                        genre_feature_id: [],
                        name: "",
                      });
                      CEvent.detail.name = item.name;
                    }
                    setSearchText("");
                    setCurrentPagination({ page: "1" });
                    document.dispatchEvent(CEvent);
                  }}
                  id={String(item.id)}
                  active={
                    currentFilter?.genre_category_id?.includes(
                      String(item.id)
                    ) || false
                  }
                  type="genre_category_id"
                />
              </div>
            ))}
            {filterFromAPI?.genre_feature_id?.values.map((item) => ( item.name !== 'Most Popular' ? // TODO: Remove this button on the API
                <div key={item.id} className="feature">
                  <FilterMenuView
                    icon_selected={item.icon_selected}
                    icon={item.icon}
                    title={item.name}
                    onClick={() => {
                      let CEvent = new CustomEvent('categoryFiltered', {
                        bubbles: true,
                        detail: { name: "" }
                      });
                      if (
                        currentFilter.genre_feature_id?.includes(
                          String(item.id)
                        )
                      ) {
                        setCurrentFilter({
                          ...currentFilter,
                          genre_feature_id: [
                            ...(currentFilter.genre_feature_id || []).filter(
                              (item) => item !== String(item)
                            ),
                          ],
                          genre_category_id: [],
                          name: "",
                        });
                      } else {
                        setCurrentFilter({
                          ...currentFilter,
                          genre_feature_id: [String(item.id)],
                          genre_category_id: [],
                          name: "",
                        });
                      }
                      document.dispatchEvent(CEvent);
                      setSearchText("");
                      setCurrentPagination({ page: "1" });
                    }}
                    id={String(item.id)}
                    active={
                      currentFilter?.genre_feature_id?.includes(
                        String(item.id)
                      ) || false
                    }
                    type="genre_feature_id"
                  />
                </div> : ''
              ))}
          </div>

          <div className="buttons">
            <div className="wrapper">
              <input
                name="searchInput"
                placeholder="Search courses by name"
                className="searchFilterCourses"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value.length >= 3 || e.target.value === "") {
                    setCurrentFilter({
                      ...currentFilter,
                      genre_category_id: [],
                      genre_feature_id: [],
                      name: e.target.value,
                    });
                    document.dispatchEvent(new CustomEvent('categoryFiltered', {
                      bubbles: true,
                      detail: { name: "" }
                    }));
                  }
                }}
              />

              {filterFromAPI?.genre_feature_id?.values.map((item) => ( item.name !== 'Most Popular' ? // TODO: Remove this button on the API
                <div key={item.id} className="feature">
                  <FilterMenuView
                    icon_selected={item.icon_selected}
                    icon={item.icon}
                    title={item.name}
                    onClick={() => {
                      let CEvent = new CustomEvent('categoryFiltered', {
                        bubbles: true,
                        detail: { name: "" }
                      });
                      if (
                        currentFilter.genre_feature_id?.includes(
                          String(item.id)
                        )
                      ) {
                        setCurrentFilter({
                          ...currentFilter,
                          genre_feature_id: [
                            ...(currentFilter.genre_feature_id || []).filter(
                              (item) => item !== String(item)
                            ),
                          ],
                          genre_category_id: [],
                          name: "",
                        });
                      } else {
                        setCurrentFilter({
                          ...currentFilter,
                          genre_feature_id: [String(item.id)],
                          genre_category_id: [],
                          name: "",
                        });
                      }
                      document.dispatchEvent(CEvent);
                      setSearchText("");
                      setCurrentPagination({ page: "1" });
                    }}
                    id={String(item.id)}
                    active={
                      currentFilter?.genre_feature_id?.includes(
                        String(item.id)
                      ) || false
                    }
                    type="genre_feature_id"
                  />
                </div> : ''
              ))}
            </div>
          </div>
        </div>
        <div className='view_type_container'>
          <div className='pagination_result'>
            {paginationData?.total > 0 && (
              <span>
                Showing {paginationData?.from} - {paginationData?.to} of{' '}
                {paginationData?.total} results
              </span>
            )}{' '}
          </div>
          <div className='view_type'>
            <div className='sort'>
              Sort by:
              <Select
                options={sortOptions}
                className='dropdown'
                onChange={(e) => {
                  setCurrentFilter({
                    ...currentFilter,
                    sort_by: e?.value as string,
                  });
                }}
                placeholder='Sort by'
                isSearchable={false}
                value={sortOptions.find(
                  (item) => item.value === currentFilter.sort_by
                )}
              />{' '}
            </div>
          </div>
        </div>
      </header>
      {coursesData.length > 0 && (
        <Pagination
          paginationData={paginationData}
          currentPage={currentPage}
          onPageChange={onPageChange}
          top={true}
          id="pagination-top-courses"
          isCourses={true}
        />
      )}
      <div className={loading ? "container_list loading" : "container_list"}>
        {coursesData.length === 0 && !loading && (
          <span className="no_results">
            Check back soon. You’ll likely see options when we finish our next
            schedule.
          </span>
        )}

        {coursesData.length === 0 &&
          loading &&
          Array.from({ length: 9 }, (_, i) => i).map((index) => (
            <div key={index} className="course_item_list">
              <div
                className="course_info_list"
                style={{ height: "140px" }}
              ></div>
            </div>
          ))}

        {coursesData.map((course) => (
          <div key={course.id} className="course_item_list">
            <a href={`/courses/${course.slug}/`} className="image">
              <img src={course.thumbnail} alt={course.name} />
            </a>
            <div className="course_info_list">
              <a href={`/courses/${course.slug}/`} className="course_list_name">
                {course.name}
              </a>
              <p
                className="course_list_content"
                dangerouslySetInnerHTML={{ __html: course.description_short }}
              />
              <div className="icons">
                {course.genre.genre_categories.map((item) => (
                  <img
                    key={item.id}
                    className="icon"
                    src={item.icon}
                    alt="Icon"
                  />
                ))}
              </div>
              <span className={course.favorite ? 'wish-list-star right active' : 'wish-list-star right '} data-type="course" data-id={course.id}><span className="label"></span></span>
            </div>
          </div>
        ))}
        {coursesData.length > 0 && (
          <Pagination
            paginationData={paginationData}
            currentPage={currentPage}
            onPageChange={onPageChange}
            top={false}
            id="pagination-bottom-courses"
            isCourses={true}
          />
        )}
      </div>
    </>
  );
}

export default App;
